<template>
  <v-app>
    <div>
      <div class="card" id="printMe" v-if="isFilter">
        <div class="card-body px-5" style="font-size: 10px">
          <!-- Title -->
          <div class="d-flex justify-content-center">
            <span class="font-weight-bolder font-size-lg">
              <u style="font-weight: bolder !important">LAPORAN PENIMBANGAN BULANAN BAYI & BALITA DI POSYANDU</u>
            </span>
          </div>
          <!-- Header -->
          <div class="row mt-0">
            <div class="col-12">
              <div class="offset-md-4">
                <div class="d-flex justify-content-start">
                  <span class="d-block"
                    >Laporan Bulanan <span class="ml-14">:</span>
                    <span class="ml-1">{{ month_name }}</span></span
                  >
                </div>
                <div class="d-flex justify-content-start">
                  <span class="d-block"
                    >Tanggal Penimbangan <span class="ml-8">:</span>
                    <span class="ml-1">{{ weighing_date }}</span></span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--I. Umum -->
          <div class="d-flex justify-content-start mt-2 font-weight-bolder">
            <span>I. UMUM</span>
          </div>
          <div class="d-flex mt-1">
            <div class="col-3 ml-1 pt-0">
              <span>A</span> <span class="ml-2">Posyandu</span>
            </div>
            <div class="offset-md-2 pl-10">
              <span>:</span>
              <span class="ml-1">{{ posyandu.name }}</span>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-3 ml-1 pt-0">
              B <span class="ml-2">Kelurahan</span>
            </div>
            <div class="offset-md-2 pl-10">
              <span>:</span> <span class="ml-1">{{ posyandu.ward }}</span>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-4 ml-1 pt-0">
              D <span class="ml-2">Petugas Lapangan yang Membina</span>
            </div>
            <div class="offset-md-1 pl-10">
              <span>:</span>
              <span class="ml-1">{{ staff.name ? staff.name : "-" }}</span>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-4 ml-1 pt-0">
              E <span class="ml-2">Jumlah Penduduk</span>
            </div>
            <div class="offset-md-1 pl-10">
              <span>:</span>
              <span class="ml-1">{{ posyandu.total_population }} Jiwa</span>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-4 ml-1 pt-0">
              F <span class="ml-2">Jumlah Kader yang Ada</span>
            </div>
            <div class="offset-md-1 pl-10">
              <span>:</span>
              <span class="ml-1">{{
                total_cadre ? total_cadre + " orang" : "Tidak ada"
              }}</span>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-4 ml-1 pt-0">
              G <span class="ml-2">Jumlah Kader yang Aktif Bulan Ini</span>
            </div>
            <div class="offset-md-1 pl-10">
              <span>:</span>
              <span class="ml-1">{{
                total_cadre ? total_cadre + " orang" : "Tidak ada"
              }}</span>
            </div>
          </div>

          <!-- II. Kegiatan -->
          <div class="row" style="margin-top: 1rem !important">
            <div class="col-4">
              <span class="font-weight-bolder"
                >II. KEGIATAN PEMANTAUAN
                <span class="ml-4">PERTUMBUHAN DAN PERKEMBANGAN</span></span
              >
              <div class="row mt-14">
                <div class="col-12 pr-0">
                  <div class="d-flex justify-content-betweeen height-tree-line">
                    <div class="col-1 pt-0 pr-0 pl-2">1.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah semua balita yang ada di wilayah kerja posyandu
                        pada bulan ini
                      </div>
                      <div class="font-weight-bolder col">(S)</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-betweeen height-tree-line">
                    <div class="col-1 pt-0 pr-0 pl-2">2.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah balita yang terdaftar dan mempunyai KMS pada
                        bulan ini
                      </div>
                      <div class="font-weight-bolder col">(K)</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-betweeen height-tree-line">
                    <div class="col-1 pt-0 pr-0 pl-2">3.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah balita yang naik berat badannya pada bulan ini
                      </div>
                      <div class="font-weight-bolder col">(N)</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-betweeen height-tree-line">
                    <div class="col-1 pt-0 pr-0 pl-2">4.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah balita yang tidak naik berat badannya pada bulan
                        ini
                      </div>
                      <div class="font-weight-bolder col">(T)</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-betweeen height-tree-line">
                    <div class="col-1 pt-0 pr-0 pl-2">5.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah balita yang ditimbang bulan ini, tetapi tidak
                        ditimbang bulan lalu
                      </div>
                      <div class="font-weight-bolder col">(O)</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-betweeen height-tree-line">
                    <div class="col-1 pt-0 pr-0 pl-2">6.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah balita yang baru pertama kali hadir di posyandu
                        bulan ini
                      </div>
                      <div class="font-weight-bolder col">(B)</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-betweeen height-two-line">
                    <div class="col-1 pt-0 pr-0 pl-2">7.</div>
                    <div class="col d-flex align-items-center pl-0 pt-0">
                      <div class="col-11 pt-2">
                        Jumlah balita yang ditimbang bulan ini ( 03 + 04 + 05 +
                        06 )
                      </div>
                      <div class="font-weight-bolder col pt-2">(D)</div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-betweeen"
                    style="height: 35px"
                  >
                    <div class="col-1 pt-0 pr-0 pl-2 pt-0">8.</div>
                    <div class="col d-flex align-items-center pl-0 pt-0">
                      <div class="col-11 pt-1">
                        Jumlah balita yang tidak hadir di posyandu pada bulan
                        ini ( 01 - 07 )
                      </div>
                      <div class="font-weight-bolder col pt-0">(-)</div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-betweeen"
                    style="height: 30px"
                  >
                    <div class="col-1 pt-0 pr-0 pl-2">9.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah balita yang dua kali berturut-turut tidak naik
                        berat badannya pada bulan ini
                      </div>
                      <div class="font-weight-bolder col">(2T)</div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-betweeen"
                    style="height: 35px"
                  >
                    <div class="col-1 pt-0 pr-0 pl-2">10.</div>
                    <div
                      class="
                        col
                        d-flex
                        align-items-center
                        justify-content-between
                        pl-0
                      "
                    >
                      <div class="col-10 pt-0">
                        Jumlah balita yang ditimbang bulan lalu dan ditimbang
                        juga pada bulan ini ( 07 - 05 - 06 atau 03 + 04 )
                      </div>
                      <div class="font-weight-bolder col text-right pr-0">
                        D-O-B
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-betweeen height-two-line">
                    <div class="col-1 pt-0 pr-0 pl-2">11.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah balita yang ada di Bawah Garis Merah (BGM)
                      </div>
                      <div class="font-weight-bolder col">&#9651;</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-betweeen height-two-line">
                    <div class="col-1 pt-0 pr-0 pl-2">12.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah balita yang mendapatkan Vitamin A
                      </div>
                      <div class="font-weight-bolder col">VIT A</div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-betweeen"
                    style="height: 50px"
                  >
                    <div class="col-1 pt-0 pr-0 pl-2">13.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah Ibu hamil KEK (Kekurangan Energi Kronis, Lila
                        &#60; 2,3cm) penemuan baru di bulan ini
                      </div>
                      <div class="font-weight-bolder col text-center">
                        KEK BARU
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-betweeen height-two-line">
                    <div class="col-1 pt-0 pr-0 pl-2">14.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah Ibu Nifas dapat Vitamin A bulan ini
                      </div>
                      <div class="font-weight-bolder col text-center">
                        Vit A BUFAS
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-betweeen height-tree-line">
                    <div class="col-1 pt-0 pr-0 pl-2">15.</div>
                    <div class="col d-flex align-items-center pl-0">
                      <div class="col-11 pt-0">
                        Jumlah Bayi Baru Lahir mendapat IMD (Inisiasi Menyusu
                        Dini)
                      </div>
                      <div class="font-weight-bolder col text-center">IMD</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8">
              <table class="table text-center" border="1" v-if="showTable" style="font-size: 10px !important">
                <tr
                  style="
                    border-top: 1.2px solid #000000;
                    border-bottom: 1.2px solid #000000;
                  "
                >
                  <th colspan="3">0 - 5 Bln</th>
                  <th colspan="3">6 - 11 Bln</th>
                  <th colspan="3">12 - 23 Bln</th>
                  <th colspan="3">24 - 59 Bln</th>
                  <th colspan="3">TOTAL</th>
                </tr>
                <tr style="border-bottom: 1.2px solid #000000">
                  <th>L</th>
                  <th>P</th>
                  <th>JML</th>
                  <th>L</th>
                  <th>P</th>
                  <th>JML</th>
                  <th>L</th>
                  <th>P</th>
                  <th>JML</th>
                  <th>L</th>
                  <th>P</th>
                  <th>JML</th>
                  <th>L</th>
                  <th>P</th>
                  <th>JML</th>
                </tr>
                <tr>
                  <th>{{ this.p1AL == 0 ? "-" : this.p1AL }}</th>
                  <th>{{ this.p1AP == 0 ? "-" : this.p1AP }}</th>
                  <th>
                    {{
                      this.p1AL + this.p1AP == 0 ? "-" : this.p1AL + this.p1AP
                    }}
                  </th>
                  <th>{{ this.p1BL == 0 ? "-" : this.p1BL }}</th>
                  <th>{{ this.p1BP == 0 ? "-" : this.p1BP }}</th>
                  <th>
                    {{
                      this.p1BL + this.p1BP == 0 ? "-" : this.p1BL + this.p1BP
                    }}
                  </th>
                  <th>{{ this.p1CL == 0 ? "-" : this.p1CL }}</th>
                  <th>{{ this.p1CP == 0 ? "-" : this.p1CP }}</th>
                  <th>
                    {{
                      this.p1CL + this.p1CP == 0 ? "-" : this.p1CL + this.p1CP
                    }}
                  </th>
                  <th>{{ this.p1DL == 0 ? "-" : this.p1DL }}</th>
                  <th>{{ this.p1DP == 0 ? "-" : this.p1DP }}</th>
                  <th>
                    {{
                      this.p1DL + this.p1DP == 0 ? "-" : this.p1DL + this.p1DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p1AL + this.p1BL + this.p1CL + this.p1DL == 0
                        ? "-"
                        : this.p1AL + this.p1BL + this.p1CL + this.p1DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p1AP + this.p1BP + this.p1CP + this.p1DP == 0
                        ? "-"
                        : this.p1AP + this.p1BP + this.p1CP + this.p1DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p1AL +
                        this.p1BL +
                        this.p1CL +
                        this.p1DL +
                        this.p1AP +
                        this.p1BP +
                        this.p1CP +
                        this.p1DP ==
                      0
                        ? "-"
                        : this.p1AL +
                          this.p1BL +
                          this.p1CL +
                          this.p1DL +
                          this.p1AP +
                          this.p1BP +
                          this.p1CP +
                          this.p1DP
                    }}
                  </th>
                </tr>
                <tr style="border-bottom: 1.5px solid #000000">
                  <th>{{ this.p2AL == 0 ? "-" : this.p2AL }}</th>
                  <th>{{ this.p2AP == 0 ? "-" : this.p2AP }}</th>
                  <th>
                    {{
                      this.p2AL + this.p2AP == 0 ? "-" : this.p2AL + this.p2AP
                    }}
                  </th>
                  <th>{{ this.p2BL == 0 ? "-" : this.p2BL }}</th>
                  <th>{{ this.p2BP == 0 ? "-" : this.p2BP }}</th>
                  <th>
                    {{
                      this.p2BL + this.p2BP == 0 ? "-" : this.p2BL + this.p2BP
                    }}
                  </th>
                  <th>{{ this.p2CL == 0 ? "-" : this.p2CL }}</th>
                  <th>{{ this.p2CP == 0 ? "-" : this.p2CP }}</th>
                  <th>
                    {{
                      this.p2CL + this.p2CP == 0 ? "-" : this.p2CL + this.p2CP
                    }}
                  </th>
                  <th>{{ this.p2DL == 0 ? "-" : this.p2DL }}</th>
                  <th>{{ this.p2DP == 0 ? "-" : this.p2DP }}</th>
                  <th>
                    {{
                      this.p2DL + this.p2DP == 0 ? "-" : this.p2DL + this.p2DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p2AL + this.p2BL + this.p2CL + this.p2DL == 0
                        ? "-"
                        : this.p2AL + this.p2BL + this.p2CL + this.p2DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p2AP + this.p2BP + this.p2CP + this.p2DP == 0
                        ? "-"
                        : this.p2AP + this.p2BP + this.p2CP + this.p2DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p2AL +
                        this.p2BL +
                        this.p2CL +
                        this.p2DL +
                        this.p2AP +
                        this.p2BP +
                        this.p2CP +
                        this.p2DP ==
                      0
                        ? "-"
                        : this.p2AL +
                          this.p2BL +
                          this.p2CL +
                          this.p2DL +
                          this.p2AP +
                          this.p2BP +
                          this.p2CP +
                          this.p2DP
                    }}
                  </th>
                </tr>
                <tr>
                  <th>{{ this.p3AL == 0 ? "-" : this.p3AL }}</th>
                  <th>{{ this.p3AP == 0 ? "-" : this.p3AP }}</th>
                  <th>
                    {{
                      this.p3AL + this.p3AP == 0 ? "-" : this.p3AL + this.p3AP
                    }}
                  </th>
                  <th>{{ this.p3BL == 0 ? "-" : this.p3BL }}</th>
                  <th>{{ this.p3BP == 0 ? "-" : this.p3BP }}</th>
                  <th>
                    {{
                      this.p3BL + this.p3BP == 0 ? "-" : this.p3BL + this.p3BP
                    }}
                  </th>
                  <th>{{ this.p3CL == 0 ? "-" : this.p3CL }}</th>
                  <th>{{ this.p3CP == 0 ? "-" : this.p3CP }}</th>
                  <th>
                    {{
                      this.p3CL + this.p3CP == 0 ? "-" : this.p3CL + this.p3CP
                    }}
                  </th>
                  <th>{{ this.p3DL == 0 ? "-" : this.p3DL }}</th>
                  <th>{{ this.p3DP == 0 ? "-" : this.p3DP }}</th>
                  <th>
                    {{
                      this.p3DL + this.p3DP == 0 ? "-" : this.p3DL + this.p3DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3AL + this.p3BL + this.p3CL + this.p3DL == 0
                        ? "-"
                        : this.p3AL + this.p3BL + this.p3CL + this.p3DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p3AP + this.p3BP + this.p3CP + this.p3DP == 0
                        ? "-"
                        : this.p3AP + this.p3BP + this.p3CP + this.p3DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3AL +
                        this.p3BL +
                        this.p3CL +
                        this.p3DL +
                        this.p3AP +
                        this.p3BP +
                        this.p3CP +
                        this.p3DP ==
                      0
                        ? "-"
                        : this.p3AL +
                          this.p3BL +
                          this.p3CL +
                          this.p3DL +
                          this.p3AP +
                          this.p3BP +
                          this.p3CP +
                          this.p3DP
                    }}
                  </th>
                </tr>
                <tr>
                  <th>{{ this.p4AL == 0 ? "-" : this.p4AL }}</th>
                  <th>{{ this.p4AP == 0 ? "-" : this.p4AP }}</th>
                  <th>
                    {{
                      this.p4AL + this.p4AP == 0 ? "-" : this.p4AL + this.p4AP
                    }}
                  </th>
                  <th>{{ this.p4BL == 0 ? "-" : this.p4BL }}</th>
                  <th>{{ this.p4BP == 0 ? "-" : this.p4BP }}</th>
                  <th>
                    {{
                      this.p4BL + this.p4BP == 0 ? "-" : this.p4BL + this.p4BP
                    }}
                  </th>
                  <th>{{ this.p4CL == 0 ? "-" : this.p4CL }}</th>
                  <th>{{ this.p4CP == 0 ? "-" : this.p4CP }}</th>
                  <th>
                    {{
                      this.p4CL + this.p4CP == 0 ? "-" : this.p4CL + this.p4CP
                    }}
                  </th>
                  <th>{{ this.p4DL == 0 ? "-" : this.p4DL }}</th>
                  <th>{{ this.p4DP == 0 ? "-" : this.p4DP }}</th>
                  <th>
                    {{
                      this.p4DL + this.p4DP == 0 ? "-" : this.p4DL + this.p4DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p4AL + this.p4BL + this.p4CL + this.p4DL == 0
                        ? "-"
                        : this.p4AL + this.p4BL + this.p4CL + this.p4DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p4AP + this.p4BP + this.p4CP + this.p4DP == 0
                        ? "-"
                        : this.p4AP + this.p4BP + this.p4CP + this.p4DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p4AL +
                        this.p4BL +
                        this.p4CL +
                        this.p4DL +
                        this.p4AP +
                        this.p4BP +
                        this.p4CP +
                        this.p4DP ==
                      0
                        ? "-"
                        : this.p4AL +
                          this.p4BL +
                          this.p4CL +
                          this.p4DL +
                          this.p4AP +
                          this.p4BP +
                          this.p4CP +
                          this.p4DP
                    }}
                  </th>
                </tr>
                <tr>
                  <th>{{ this.p5AL == 0 ? "-" : this.p5AL }}</th>
                  <th>{{ this.p5AP == 0 ? "-" : this.p5AP }}</th>
                  <th>
                    {{
                      this.p5AL + this.p5AP == 0 ? "-" : this.p5AL + this.p5AP
                    }}
                  </th>
                  <th>{{ this.p5BL == 0 ? "-" : this.p5BL }}</th>
                  <th>{{ this.p5BP == 0 ? "-" : this.p5BP }}</th>
                  <th>
                    {{
                      this.p5BL + this.p5BP == 0 ? "-" : this.p5BL + this.p5BP
                    }}
                  </th>
                  <th>{{ this.p5CL == 0 ? "-" : this.p5CL }}</th>
                  <th>{{ this.p5CP == 0 ? "-" : this.p5CP }}</th>
                  <th>
                    {{
                      this.p5CL + this.p5CP == 0 ? "-" : this.p5CL + this.p5CP
                    }}
                  </th>
                  <th>{{ this.p5DL == 0 ? "-" : this.p5DL }}</th>
                  <th>{{ this.p5DP == 0 ? "-" : this.p5DP }}</th>
                  <th>
                    {{
                      this.p5DL + this.p5DP == 0 ? "-" : this.p5DL + this.p5DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p5AL + this.p5BL + this.p5CL + this.p5DL == 0
                        ? "-"
                        : this.p5AL + this.p5BL + this.p5CL + this.p5DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p5AP + this.p5BP + this.p5CP + this.p5DP == 0
                        ? "-"
                        : this.p5AP + this.p5BP + this.p5CP + this.p5DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p5AL +
                        this.p5BL +
                        this.p5CL +
                        this.p5DL +
                        this.p5AP +
                        this.p5BP +
                        this.p5CP +
                        this.p5DP ==
                      0
                        ? "-"
                        : this.p5AL +
                          this.p5BL +
                          this.p5CL +
                          this.p5DL +
                          this.p5AP +
                          this.p5BP +
                          this.p5CP +
                          this.p5DP
                    }}
                  </th>
                </tr>
                <tr style="border-bottom: 1.1px solid #000000">
                  <th>{{ this.p6AL == 0 ? "-" : this.p6AL }}</th>
                  <th>{{ this.p6AP == 0 ? "-" : this.p6AP }}</th>
                  <th>
                    {{
                      this.p6AL + this.p6AP == 0 ? "-" : this.p6AL + this.p6AP
                    }}
                  </th>
                  <th>{{ this.p6BL == 0 ? "-" : this.p6BL }}</th>
                  <th>{{ this.p6BP == 0 ? "-" : this.p6BP }}</th>
                  <th>
                    {{
                      this.p6BL + this.p6BP == 0 ? "-" : this.p6BL + this.p6BP
                    }}
                  </th>
                  <th>{{ this.p6CL == 0 ? "-" : this.p6CL }}</th>
                  <th>{{ this.p6CP == 0 ? "-" : this.p6CP }}</th>
                  <th>
                    {{
                      this.p6CL + this.p6CP == 0 ? "-" : this.p6CL + this.p6CP
                    }}
                  </th>
                  <th>{{ this.p6DL == 0 ? "-" : this.p6DL }}</th>
                  <th>{{ this.p6DP == 0 ? "-" : this.p6DP }}</th>
                  <th>
                    {{
                      this.p6DL + this.p6DP == 0 ? "-" : this.p6DL + this.p6DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p6AL + this.p6BL + this.p6CL + this.p6DL == 0
                        ? "-"
                        : this.p6AL + this.p6BL + this.p6CL + this.p6DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p6AP + this.p6BP + this.p6CP + this.p6DP == 0
                        ? "-"
                        : this.p6AP + this.p6BP + this.p6CP + this.p6DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p6AL +
                        this.p6BL +
                        this.p6CL +
                        this.p6DL +
                        this.p6AP +
                        this.p6BP +
                        this.p6CP +
                        this.p6DP ==
                      0
                        ? "-"
                        : this.p6AL +
                          this.p6BL +
                          this.p6CL +
                          this.p6DL +
                          this.p6AP +
                          this.p6BP +
                          this.p6CP +
                          this.p6DP
                    }}
                  </th>
                </tr>
                <tr style="border-bottom: 1.2px solid #000000">
                  <th>{{ this.p7AL == 0 ? "-" : this.p7AL }}</th>
                  <th>{{ this.p7AP == 0 ? "-" : this.p7AP }}</th>
                  <th>
                    {{
                      this.p7AL + this.p7AP == 0 ? "-" : this.p7AL + this.p7AP
                    }}
                  </th>
                  <th>{{ this.p7BL == 0 ? "-" : this.p7BL }}</th>
                  <th>{{ this.p7BP == 0 ? "-" : this.p7BP }}</th>
                  <th>
                    {{
                      this.p7BL + this.p7BP == 0 ? "-" : this.p7BL + this.p7BP
                    }}
                  </th>
                  <th>{{ this.p7CL == 0 ? "-" : this.p7CL }}</th>
                  <th>{{ this.p7CP == 0 ? "-" : this.p7CP }}</th>
                  <th>
                    {{
                      this.p7CL + this.p7CP == 0 ? "-" : this.p7CL + this.p7CP
                    }}
                  </th>
                  <th>{{ this.p7DL == 0 ? "-" : this.p7DL }}</th>
                  <th>{{ this.p7DP == 0 ? "-" : this.p7DP }}</th>
                  <th>
                    {{
                      this.p7DL + this.p7DP == 0 ? "-" : this.p7DL + this.p7DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p7AL + this.p7BL + this.p7CL + this.p7DL == 0
                        ? "-"
                        : this.p7AL + this.p7BL + this.p7CL + this.p7DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p7AP + this.p7BP + this.p7CP + this.p7DP == 0
                        ? "-"
                        : this.p7AP + this.p7BP + this.p7CP + this.p7DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p7AL +
                        this.p7BL +
                        this.p7CL +
                        this.p7DL +
                        this.p7AP +
                        this.p7BP +
                        this.p7CP +
                        this.p7DP ==
                      0
                        ? "-"
                        : this.p7AL +
                          this.p7BL +
                          this.p7CL +
                          this.p7DL +
                          this.p7AP +
                          this.p7BP +
                          this.p7CP +
                          this.p7DP
                    }}
                  </th>
                </tr>
                <tr>
                  <th>{{ this.p8AL == 0 ? "-" : this.p8AL }}</th>
                  <th>{{ this.p8AP == 0 ? "-" : this.p8AP }}</th>
                  <th>
                    {{
                      this.p8AL + this.p8AP == 0 ? "-" : this.p8AL + this.p8AP
                    }}
                  </th>
                  <th>{{ this.p8BL == 0 ? "-" : this.p8BL }}</th>
                  <th>{{ this.p8BP == 0 ? "-" : this.p8BP }}</th>
                  <th>
                    {{
                      this.p8BL + this.p8BP == 0 ? "-" : this.p8BL + this.p8BP
                    }}
                  </th>
                  <th>{{ this.p8CL == 0 ? "-" : this.p8CL }}</th>
                  <th>{{ this.p8CP == 0 ? "-" : this.p8CP }}</th>
                  <th>
                    {{
                      this.p8CL + this.p8CP == 0 ? "-" : this.p8CL + this.p8CP
                    }}
                  </th>
                  <th>{{ this.p8DL == 0 ? "-" : this.p8DL }}</th>
                  <th>{{ this.p8DP == 0 ? "-" : this.p8DP }}</th>
                  <th>
                    {{
                      this.p8DL + this.p8DP == 0 ? "-" : this.p8DL + this.p8DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p8AL + this.p8BL + this.p8CL + this.p8DL == 0
                        ? "-"
                        : this.p8AL + this.p8BL + this.p8CL + this.p8DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p8AP + this.p8BP + this.p8CP + this.p8DP == 0
                        ? "-"
                        : this.p8AP + this.p8BP + this.p8CP + this.p8DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p8AL +
                        this.p8BL +
                        this.p8CL +
                        this.p8DL +
                        this.p8AP +
                        this.p8BP +
                        this.p8CP +
                        this.p8DP ==
                      0
                        ? "-"
                        : this.p8AL +
                          this.p8BL +
                          this.p8CL +
                          this.p8DL +
                          this.p8AP +
                          this.p8BP +
                          this.p8CP +
                          this.p8DP
                    }}
                  </th>
                </tr>
                <th>{{ this.p9AL == 0 ? "-" : this.p9AL }}</th>
                <th>{{ this.p9AP == 0 ? "-" : this.p9AP }}</th>
                <th>
                  {{ this.p9AL + this.p9AP == 0 ? "-" : this.p9AL + this.p9AP }}
                </th>
                <th>{{ this.p9BL == 0 ? "-" : this.p9BL }}</th>
                <th>{{ this.p9BP == 0 ? "-" : this.p9BP }}</th>
                <th>
                  {{ this.p9BL + this.p9BP == 0 ? "-" : this.p9BL + this.p9BP }}
                </th>
                <th>{{ this.p9CL == 0 ? "-" : this.p9CL }}</th>
                <th>{{ this.p9CP == 0 ? "-" : this.p9CP }}</th>
                <th>
                  {{ this.p9CL + this.p9CP == 0 ? "-" : this.p9CL + this.p9CP }}
                </th>
                <th>{{ this.p9DL == 0 ? "-" : this.p9DL }}</th>
                <th>{{ this.p9DP == 0 ? "-" : this.p9DP }}</th>
                <th>
                  {{ this.p9DL + this.p9DP == 0 ? "-" : this.p9DL + this.p9DP }}
                </th>
                <th>
                  {{
                    this.p9AL + this.p9BL + this.p9CL + this.p9DL == 0
                      ? "-"
                      : this.p9AL + this.p9BL + this.p9CL + this.p9DL
                  }}
                </th>
                <th>
                  {{
                    this.p9AP + this.p9BP + this.p9CP + this.p9DP == 0
                      ? "-"
                      : this.p9AP + this.p9BP + this.p9CP + this.p9DP
                  }}
                </th>
                <th>
                  {{
                    this.p9AL +
                      this.p9BL +
                      this.p9CL +
                      this.p9DL +
                      this.p9AP +
                      this.p9BP +
                      this.p9CP +
                      this.p9DP ==
                    0
                      ? "-"
                      : this.p9AL +
                        this.p9BL +
                        this.p9CL +
                        this.p9DL +
                        this.p9AP +
                        this.p9BP +
                        this.p9CP +
                        this.p9DP
                  }}
                </th>
                <tr>
                  <th>
                    {{
                      this.p3AL + this.p4AL == 0 ? "-" : this.p3AL + this.p4AL
                    }}
                  </th>
                  <th>
                    {{
                      this.p3AP + this.p4AP == 0 ? "-" : this.p3AP + this.p4AP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3AL + this.p4AL + this.p3AP + this.p4AP == 0
                        ? "-"
                        : this.p3AL + this.p4AL + this.p3AP + this.p4AP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3BL + this.p4BL == 0 ? "-" : this.p3BL + this.p4BL
                    }}
                  </th>
                  <th>
                    {{
                      this.p3BP + this.p4BP == 0 ? "-" : this.p3BP + this.p4BP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3BL + this.p4BL + this.p3BP + this.p4BP == 0
                        ? "-"
                        : this.p3BL + this.p4BL + this.p3BP + this.p4BP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3CL + this.p4CL == 0 ? "-" : this.p3CL + this.p4CL
                    }}
                  </th>
                  <th>
                    {{
                      this.p3CP + this.p4CP == 0 ? "-" : this.p3CP + this.p4CP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3CL + this.p4CL + this.p3CP + this.p4CP == 0
                        ? "-"
                        : this.p3CL + this.p4CL + this.p3CP + this.p4CP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3DL + this.p4DL == 0 ? "-" : this.p3DL + this.p4DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p3DP + this.p4DP == 0 ? "-" : this.p3DP + this.p4DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3DL + this.p4DL + this.p3DP + this.p4DP == 0
                        ? "-"
                        : this.p3DL + this.p4DL + this.p3DP + this.p4DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3AL +
                      this.p4AL +
                      this.p3BL +
                      this.p4BL +
                      this.p3CL +
                      this.p4CL +
                      this.p3DL +
                      this.p4DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p3AP +
                      this.p4AP +
                      this.p3BP +
                      this.p4BP +
                      this.p3CP +
                      this.p4CP +
                      this.p3DP +
                      this.p4DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p3AL +
                      this.p4AL +
                      this.p3BL +
                      this.p4BL +
                      this.p3CL +
                      this.p4CL +
                      this.p3DL +
                      this.p4DL +
                      this.p3AP +
                      this.p4AP +
                      this.p3BP +
                      this.p4BP +
                      this.p3CP +
                      this.p4CP +
                      this.p3DP +
                      this.p4DP
                    }}
                  </th>
                </tr>
                <tr style="border-bottom: 1.2px solid #000000">
                  <th>{{ this.p11AL == 0 ? "-" : this.p11AL }}</th>
                  <th>{{ this.p11AP == 0 ? "-" : this.p11AP }}</th>
                  <th>
                    {{
                      this.p11AL + this.p11AP == 0
                        ? "-"
                        : this.p11AL + this.p11AP
                    }}
                  </th>
                  <th>{{ this.p11BL == 0 ? "-" : this.p11BL }}</th>
                  <th>{{ this.p11BP == 0 ? "-" : this.p11BP }}</th>
                  <th>
                    {{
                      this.p11BL + this.p11BP == 0
                        ? "-"
                        : this.p11BL + this.p11BP
                    }}
                  </th>
                  <th>{{ this.p11CL == 0 ? "-" : this.p11CL }}</th>
                  <th>{{ this.p11CP == 0 ? "-" : this.p11CP }}</th>
                  <th>
                    {{
                      this.p11CL + this.p11CP == 0
                        ? "-"
                        : this.p11CL + this.p11CP
                    }}
                  </th>
                  <th>{{ this.p11DL == 0 ? "-" : this.p11DL }}</th>
                  <th>{{ this.p11DP == 0 ? "-" : this.p11DP }}</th>
                  <th>
                    {{
                      this.p11DL + this.p11DP == 0
                        ? "-"
                        : this.p11DL + this.p11DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p11AL + this.p11BL + this.p11CL + this.p11DL == 0
                        ? "-"
                        : this.p11AL + this.p11BL + this.p11CL + this.p11DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p11AP + this.p11BP + this.p11CP + this.p11DP == 0
                        ? "-"
                        : this.p11AP + this.p11BP + this.p11CP + this.p11DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p11AL +
                        this.p11BL +
                        this.p11CL +
                        this.p11DL +
                        this.p11AP +
                        this.p11BP +
                        this.p11CP +
                        this.p11DP ==
                      0
                        ? "-"
                        : this.p11AL +
                          this.p11BL +
                          this.p11CL +
                          this.p11DL +
                          this.p11AP +
                          this.p11BP +
                          this.p11CP +
                          this.p11DP
                    }}
                  </th>
                </tr>
                <tr style="border-bottom: 1.2px solid #000000">
                  <th>{{ this.p12AL == 0 ? "-" : this.p12AL }}</th>
                  <th>{{ this.p12AP == 0 ? "-" : this.p12AP }}</th>
                  <th>
                    {{
                      this.p12AL + this.p12AP == 0
                        ? "-"
                        : this.p12AL + this.p12AP
                    }}
                  </th>
                  <th>{{ this.p12BL == 0 ? "-" : this.p12BL }}</th>
                  <th>{{ this.p12BP == 0 ? "-" : this.p12BP }}</th>
                  <th>
                    {{
                      this.p12BL + this.p12BP == 0
                        ? "-"
                        : this.p12BL + this.p12BP
                    }}
                  </th>
                  <th>{{ this.p12CL == 0 ? "-" : this.p12CL }}</th>
                  <th>{{ this.p12CP == 0 ? "-" : this.p12CP }}</th>
                  <th>
                    {{
                      this.p12CL + this.p12CP == 0
                        ? "-"
                        : this.p12CL + this.p12CP
                    }}
                  </th>
                  <th>{{ this.p12DL == 0 ? "-" : this.p12DL }}</th>
                  <th>{{ this.p12DP == 0 ? "-" : this.p12DP }}</th>
                  <th>
                    {{
                      this.p12DL + this.p12DP == 0
                        ? "-"
                        : this.p12DL + this.p12DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p12AL + this.p12BL + this.p12CL + this.p12DL == 0
                        ? "-"
                        : this.p12AL + this.p12BL + this.p12CL + this.p12DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p12AP + this.p12BP + this.p12CP + this.p12DP == 0
                        ? "-"
                        : this.p12AP + this.p12BP + this.p12CP + this.p12DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p12AL +
                        this.p12BL +
                        this.p12CL +
                        this.p12DL +
                        this.p12AP +
                        this.p12BP +
                        this.p12CP +
                        this.p12DP ==
                      0
                        ? "-"
                        : this.p12AL +
                          this.p12BL +
                          this.p12CL +
                          this.p12DL +
                          this.p12AP +
                          this.p12BP +
                          this.p12CP +
                          this.p12DP
                    }}
                  </th>
                </tr>
                <tr>
                  <th colspan="15">{{ this.p13 }}</th>
                </tr>
                <tr style="border-bottom: 1.1px solid #000000">
                  <th colspan="15">{{ this.p14 }}</th>
                </tr>
                <tr style="border-bottom: 1.1px solid #000000">
                  <th>{{ this.p15AL == 0 ? "-" : this.p15AL }}</th>
                  <th>{{ this.p15AP == 0 ? "-" : this.p15AP }}</th>
                  <th>
                    {{
                      this.p15AL + this.p15AP == 0
                        ? "-"
                        : this.p15AL + this.p15AP
                    }}
                  </th>
                  <th>{{ this.p15BL == 0 ? "-" : this.p15BL }}</th>
                  <th>{{ this.p15BP == 0 ? "-" : this.p15BP }}</th>
                  <th>
                    {{
                      this.p15BL + this.p15BP == 0
                        ? "-"
                        : this.p15BL + this.p15BP
                    }}
                  </th>
                  <th>{{ this.p15CL == 0 ? "-" : this.p15CL }}</th>
                  <th>{{ this.p15CP == 0 ? "-" : this.p15CP }}</th>
                  <th>
                    {{
                      this.p15CL + this.p15CP == 0
                        ? "-"
                        : this.p15CL + this.p15CP
                    }}
                  </th>
                  <th>{{ this.p15DL == 0 ? "-" : this.p15DL }}</th>
                  <th>{{ this.p15DP == 0 ? "-" : this.p15DP }}</th>
                  <th>
                    {{
                      this.p15DL + this.p15DP == 0
                        ? "-"
                        : this.p15DL + this.p15DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p15AL + this.p15BL + this.p15CL + this.p15DL == 0
                        ? "-"
                        : this.p15AL + this.p15BL + this.p15CL + this.p15DL
                    }}
                  </th>
                  <th>
                    {{
                      this.p15AP + this.p15BP + this.p15CP + this.p15DP == 0
                        ? "-"
                        : this.p15AP + this.p15BP + this.p15CP + this.p15DP
                    }}
                  </th>
                  <th>
                    {{
                      this.p15AL +
                        this.p15BL +
                        this.p15CL +
                        this.p15DL +
                        this.p15AP +
                        this.p15BP +
                        this.p15CP +
                        this.p15DP ==
                      0
                        ? "-"
                        : this.p15AL +
                          this.p15BL +
                          this.p15CL +
                          this.p15DL +
                          this.p15AP +
                          this.p15BP +
                          this.p15CP +
                          this.p15DP
                    }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
          <!-- III Kegiatan -->
          <div class="d-flex justify-content-start mt-3">
            <span class="font-weight-bolder">III. LAPORAN ASI EKSKLUSIF</span>
          </div>
          <div class="row">
            <div class="col-3">
              <span class="font-weight-bolder ml-14">LULUS ASI EKSKLUSIF</span>
              <div class="d-flex justify-content-start align-items-start ml-3">
                <div class="col-1">a.</div>
                <div class="col">
                  Jumlah bayi yang pindah pencatatan SIP dari kelompok 0-5 bulan
                  ke 6-11 bulan pada bulan ini yang pada saat umur 0-5 bulan 29
                  hari mendapat ASI Eksklusif
                </div>
              </div>
              <div class="d-flex justify-content-start align-items-start ml-3">
                <div class="col-1">b.</div>
                <div class="col">
                  Jumlah bayi yang pindah pencatatan SIP dari kelompok 0-5 bulan
                  ke 6-11 bulan pada bulan ini
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="d-flex justify-content-start align-items-center">
                <div class="col-4 px-0 text-center">
                  <span class="d-block mt-12 font-size-sm">LULUS ASI EKS</span>
                  <span class="d-block mt-8 font-size-sm"
                    >(S) PINDAH CATAT SIP KE 6-11 BLN</span
                  >
                </div>
                <div class="col pt-2">
                  <table class="table table-bordered" style="font-size: 10px !important">
                    <tr>
                      <th>L</th>
                      <th>P</th>
                      <th>JML</th>
                    </tr>
                    <tr>
                      <td>{{ exclusive1AL == 0 ? "-" : exclusive1AL }}</td>
                      <td>{{ exclusive1AP == 0 ? "-" : exclusive1AP }}</td>
                      <td>
                        {{
                          exclusive1AL + exclusive1AP == 0
                            ? "-"
                            : exclusive1AL + exclusive1AP
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ exclusive1BL == 0 ? "-" : exclusive1BL }}</td>
                      <td>{{ exclusive1BP == 0 ? "-" : exclusive1BP }}</td>
                      <td>
                        {{
                          exclusive1BL + exclusive1BP == 0
                            ? "-"
                            : exclusive1BL + exclusive1BP
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-3">
              <span class="font-weight-bolder ml-14"
                >ASI EKSKLUSIF BULANAN</span
              >
              <div class="d-flex justify-content-start align-items-start ml-3">
                <div class="col-1">a.</div>
                <div class="col">
                  Jumlah bayi 0-5 bulan 29 hari mendapat ASI Eksklusif ditimbang
                  bulan ini dan masih mendapat ASI ekslusif pada bulan ini
                </div>
              </div>
              <div class="d-flex justify-content-start align-items-start ml-3">
                <div class="col-1">b.</div>
                <div class="col">
                  Jumlah bayi 0-5 bulan 29 hari yang ditimbang bulan ini yang
                  sudah diberi makanan selain ASI pada bulan ini
                </div>
              </div>
              <span>Catatan : &radic; + X = D (0-5 Bln)</span>
            </div>
            <div class="col-3">
              <div class="d-flex justify-content-start align-items-center">
                <div class="col-4 text-center">
                  <span class="d-block mt-12 font-size-sm">&radic;</span>
                  <span class="d-block mt-8 font-size-sm">X</span>
                </div>
                <div class="col">
                  <table class="table table-bordered">
                    <tr>
                      <th>L</th>
                      <th>P</th>
                      <th>JML</th>
                    </tr>
                    <tr>
                      <td>{{ exclusive2AL == 0 ? "-" : exclusive2AL }}</td>
                      <td>{{ exclusive2AP == 0 ? "-" : exclusive2AP }}</td>
                      <td>
                        {{
                          exclusive2AL + exclusive2AP == 0
                            ? "-"
                            : exclusive2AL + exclusive2AP
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ exclusive2AL == 0 ? "-" : exclusive2AL }}</td>
                      <td>{{ exclusive2AP == 0 ? "-" : exclusive2AP }}</td>
                      <td>
                        {{
                          exclusive2AL + exclusive2AP == 0
                            ? "-"
                            : exclusive2AL + exclusive2AP
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- IV Persediaan -->
          <div class="d-flex justify-content-start mt-3">
            <span class="font-weight-bolder">IV. PERSEDIAAN BAHAN-BAHAN</span>
          </div>
          <div class="row">
            <div class="col-12">
              <table class="table table-bordered">
                <tr class="text-center">
                  <th>No.</th>
                  <th>Bahan - Bahan</th>
                  <th>Sisa Akhir Bulan Lalu</th>
                  <th>Diterima Bulan Ini</th>
                  <th>Dikeluarkan Bulan Ini</th>
                  <th>Sisa Akhir Bulan Ini</th>
                </tr>
                <tr v-for="(item, index) in materialStocks" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.last_stock == 0 ? "-" : item.last_stock }}</td>
                  <td>
                    {{
                      item.stock_in_this_month == 0
                        ? "-"
                        : item.stock_in_this_month
                    }}
                  </td>
                  <td>
                    {{
                      item.stock_out_this_month == 0
                        ? "-"
                        : item.stock_out_this_month
                    }}
                  </td>
                  <td>
                    {{ item.remaining_stock == 0 ? "-" : item.remaining_stock }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- V Keterangan Lain -->
          <div class="d-flex justify-content-start mt-3">
            <span class="font-weight-bolder">V. KETERANGAN LAIN</span>
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-6">
              <span class="d-block"
                >Harap di tulis di belakang kertas F1 Fizi</span
              >
              <span class="d-block">Data Balita BGM dan 2T</span>
              <span>-</span><span class="ml-4">Nama Balita, Tanggal Lahir</span
              ><br />
              <span>-</span
              ><span class="ml-4">Nama Orang Tua, Alamat, No Hp</span>
              <span class="d-block">Data Ibu Hamil KEK</span>
              <span>-</span><span class="ml-4">Nama, Umur, Usia Kehamilan</span
              ><br />
              <span>-</span><span class="ml-4">Alamat, No Hp</span>
            </div>
            <div class="col-6">
              <div class="d-flex justify-content-start">
                <span>Ketua Posyandu</span>
                <span class="ml-7">: Ida Noviar</span>
              </div>
              <div class="d-flex justify-content-start">
                <span>Tanda Tangan</span>
                <span class="ml-10">:</span>
              </div>
              <div class="d-flex justify-content-start">
                <span>Tanggal Laporan</span>
                <span class="ml-5">: 10-12-2019</span>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <div class="text-center">
                  <span class="font-weight-bolder d-block">KEGIATAN</span>
                  <span class="font-weight-bolder d-block"
                    >PENANGGULANGAN KEP, ANEMIA GIZI BESI, GAKY, KVA DAN</span
                  >
                  <span class="font-weight-bolder d-block"
                    >KEKURANGAN ZAT GIZI MIKRO LAINNYA</span
                  >
                  <span class="font-weight-bolder d-block"
                    >DINAS KESEHATAN KOTA TANGERANG</span
                  >
                  <span class="font-weight-bolder d-block"
                    >APBD II TAHUN ANGGARAN 2019</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";
import api from "@/core/services/api.service.js";

export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 7),
      modal: false,
      currentUser: getUser(),
      posyandu: [],
      posyandu_list: [],
      filter: {
        month: "",
        posyandu_id:
          getUser().role_key_name == "super_admin" ||
          getUser().user_type_id == 3
            ? ""
            : getUser().posyandu_id,
      },
      monthOptions: [
        { value: "", text: "Pilih Bulan", disabled: true },
        { value: "01", text: "Januari" },
        { value: "02", text: "Februari" },
        { value: "03", text: "Maret" },
        { value: "04", text: "April" },
        { value: "05", text: "Mei" },
        { value: "06", text: "Juni" },
        { value: "07", text: "Juli" },
        { value: "08", text: "Agustus" },
        { value: "09", text: "September" },
        { value: "10", text: "Oktober" },
        { value: "11", text: "November" },
        { value: "12", text: "Desember" },
      ],
      isFilter: false,
      chooseMonth: false,
      showTable: true,
      monthSelected: "",
      // umum
      total_cadre: "",
      staff: "",
      month_name: "",
      weighing_date: "",
      // table report
      // p1
      p1AL: "",
      p1AP: "",
      p1BL: "",
      p1BP: "",
      p1CL: "",
      p1CP: "",
      p1DL: "",
      p1DP: "",
      // p2
      p2AL: "",
      p2AP: "",
      p2BL: "",
      p2BP: "",
      p2CL: "",
      p2CP: "",
      p2DL: "",
      p2DP: "",
      // p3
      p3AL: "",
      p3AP: "",
      p3BL: "",
      p3BP: "",
      p3CL: "",
      p3CP: "",
      p3DL: "",
      p3DP: "",
      // p4
      p4AL: "",
      p4AP: "",
      p4BL: "",
      p4BP: "",
      p4CL: "",
      p4CP: "",
      p4DL: "",
      p4DP: "",
      // p5
      p5AL: "",
      p5AP: "",
      p5BL: "",
      p5BP: "",
      p5CL: "",
      p5CP: "",
      p5DL: "",
      p5DP: "",
      // p6
      p6AL: "",
      p6AP: "",
      p6BL: "",
      p6BP: "",
      p6CL: "",
      p6CP: "",
      p6DL: "",
      p6DP: "",
      // p7
      p7AL: "",
      p7AP: "",
      p7BL: "",
      p7BP: "",
      p7CL: "",
      p7CP: "",
      p7DL: "",
      p7DP: "",
      // p8
      p8AL: "",
      p8AP: "",
      p8BL: "",
      p8BP: "",
      p8CL: "",
      p8CP: "",
      p8DL: "",
      p8DP: "",
      // p9
      p9AL: "",
      p9AP: "",
      p9BL: "",
      p9BP: "",
      p9CL: "",
      p9CP: "",
      p9DL: "",
      p9DP: "",
      // p11
      p11AL: "",
      p11AP: "",
      p11BL: "",
      p11BP: "",
      p11CL: "",
      p11CP: "",
      p11DL: "",
      p11DP: "",
      // p12
      p12AL: "",
      p12AP: "",
      p12BL: "",
      p12BP: "",
      p12CL: "",
      p12CP: "",
      p12DL: "",
      p12DP: "",
      // p13
      p13: "",
      // p14
      p14: "",
      // p15
      p15AL: "",
      p15AP: "",
      p15BL: "",
      p15BP: "",
      p15CL: "",
      p15CP: "",
      p15DL: "",
      p15DP: "",
      // Laporan ASI EKSKLUSIF
      // exclusive 1
      exclusive1AL: "",
      exclusive1AP: "",
      exclusive1BL: "",
      exclusive1BP: "",
      // exclusive 2
      exclusive2AL: "",
      exclusive2AP: "",
      exclusive2BL: "",
      exclusive2BP: "",
      // material stocks
      materialStocks: [],
    };
  },
  methods: {
    async getPosyandu(id) {
      console.log("get posyandu");
      this.posyandu = await module.get("api/posyandu/" + id);
      // If Data Not Found
      if (this.posyandu == null) {
        // Redirect To List
        // this.$router.push('/toddler')
      } else {
      }
    },
    async getPosyanduOption() {
      let response = await module.setTreeSelect("api/posyandu");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.posyandu_list = response.data;
        this.posyandu_list.unshift({
          label: "Pilih Posyandu",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByPosyandu(evt) {
      if (typeof evt != "undefined") {
        this.filter.posyandu_id = evt;
        this.isFilter = true;
        this.getPosyandu(evt);
        // this.pagination()
      } else {
        this.filter.posyandu_id = "";
        this.isFilter = false;
        // this.pagination()
      }
    },

    filterByMonth(evt) {
      if (
        getUser().role_key_name != "super_admin" &&
        getUser().user_type_id != 3
      ) {
        this.isFilter = true;
      }
      this.chooseMonth = true;
      this.$refs.dialog.save(this.filter.month);
      console.log("evt", evt);
      console.log("filter", this.filter);

      // umum
      this.getTotalCadre();
      this.getStaff();
      this.getWeighingDate();
      // p1
      this.getP1AL();
      this.getP1AP();
      this.getP1BL();
      this.getP1BP();
      this.getP1CL();
      this.getP1CP();
      this.getP1DL();
      this.getP1DP();
      // p2
      this.getP2AL();
      this.getP2AP();
      this.getP2BL();
      this.getP2BP();
      this.getP2CL();
      this.getP2CP();
      this.getP2DL();
      this.getP2DP();
      // p3
      this.getP3AL();
      this.getP3AP();
      this.getP3BL();
      this.getP3BP();
      this.getP3CL();
      this.getP3CP();
      this.getP3DL();
      this.getP3DP();
      // p4
      this.getP4AL();
      this.getP4AP();
      this.getP4BL();
      this.getP4BP();
      this.getP4CL();
      this.getP4CP();
      this.getP4DL();
      this.getP4DP();
      // p5
      this.getP5AL();
      this.getP5AP();
      this.getP5BL();
      this.getP5BP();
      this.getP5CL();
      this.getP5CP();
      this.getP5DL();
      this.getP5DP();
      // p6
      this.getP6AL();
      this.getP6AP();
      this.getP6BL();
      this.getP6BP();
      this.getP6CL();
      this.getP6CP();
      this.getP6DL();
      this.getP6DP();
      // p7
      this.getP7AL();
      this.getP7AP();
      this.getP7BL();
      this.getP7BP();
      this.getP7CL();
      this.getP7CP();
      this.getP7DL();
      this.getP7DP();
      // p8
      this.getP8AL();
      this.getP8AP();
      this.getP8BL();
      this.getP8BP();
      this.getP8CL();
      this.getP8CP();
      this.getP8DL();
      this.getP8DP();
      // p9
      this.getP9AL();
      this.getP9AP();
      this.getP9BL();
      this.getP9BP();
      this.getP9CL();
      this.getP9CP();
      this.getP9DL();
      this.getP9DP();
      // p11
      this.getP11AL();
      this.getP11AP();
      this.getP11BL();
      this.getP11BP();
      this.getP11CL();
      this.getP11CP();
      this.getP11DL();
      this.getP11DP();
      // p12
      this.getP12AL();
      this.getP12AP();
      this.getP12BL();
      this.getP12BP();
      this.getP12CL();
      this.getP12CP();
      this.getP12DL();
      this.getP12DP();
      // p13
      this.getP13();
      // p14
      this.getP14();
      // p15
      this.getP15AL();
      this.getP15AP();
      this.getP15BL();
      this.getP15BP();
      this.getP15CL();
      this.getP15CP();
      this.getP15DL();
      this.getP15DP();

      // LAPORAN ASI EKSLUSIF
      // exclusive 1
      this.getExclusive1AL();
      this.getExclusive1AP();
      this.getExclusive1BL();
      this.getExclusive1BP();
      // exclusive 2
      this.getExclusive2AL();
      this.getExclusive2AP();
      this.getExclusive2BL();
      this.getExclusive2BP();

      // PERSEDIAN BAHAN-BAHAN
      this.getMaterialStock();
    },

    // total kader
    async getTotalCadre() {
      this.total_cadre = await module.getReport(
        "api/cadres/report/total-cadres/" + getUser().posyandu_id,
        "?age_category=A&gender=male"
      );
      if (this.total_cadre == null) {
        this.total_cadre = 0;
      }
    },
    async getStaff() {
      let response = await module.getReport("api/staffs");
      console.log(response);
      if (response == null) {
        response = "-";
      } else {
        this.staff = response.data[0];
        console.log(this.staff);
      }
    },
    async getWeighingDate() {
      let response = await module.getReport(
        "api/weighing-note/date/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      this.month_name = response.month_name;
      this.weighing_date = response.weighing_date;
    },

    // p1
    async getP1AL() {
      this.p1AL = await module.getReport(
        "api/p1/" + getUser().posyandu_id,
        "?age_category=A&gender=male"
      );
      if (this.p1AL == null) {
        this.p1AL = 0;
      }
    },
    async getP1AP() {
      this.p1AP = await module.getReport(
        "api/p1/" + getUser().posyandu_id,
        "?age_category=A&gender=female"
      );
      if (this.p1AP == null) {
        this.p1AP = 0;
      }
    },
    async getP1BL() {
      this.p1BL = await module.getReport(
        "api/p1/" + getUser().posyandu_id,
        "?age_category=B&gender=male"
      );
      if (this.p1BL == null) {
        this.p1BL = 0;
      }
    },
    async getP1BP() {
      this.p1BP = await module.getReport(
        "api/p1/" + getUser().posyandu_id,
        "?age_category=B&gender=female"
      );
      if (this.p1BP == null) {
        this.p1BP = 0;
      }
    },
    async getP1CL() {
      this.p1CL = await module.getReport(
        "api/p1/" + getUser().posyandu_id,
        "?age_category=C&gender=male"
      );
      if (this.p1CL == null) {
        this.p1CL = 0;
      }
    },
    async getP1CP() {
      this.p1CP = await module.getReport(
        "api/p1/" + getUser().posyandu_id,
        "?age_category=C&gender=female"
      );
      if (this.p1CP == null) {
        this.p1CP = 0;
      }
    },
    async getP1DL() {
      this.p1DL = await module.getReport(
        "api/p1/" + getUser().posyandu_id,
        "?age_category=D&gender=male"
      );
      if (this.p1DL == null) {
        this.p1DL = 0;
      }
    },
    async getP1DP() {
      this.p1DP = await module.getReport(
        "api/p1/" + getUser().posyandu_id,
        "?age_category=D&gender=female"
      );
      if (this.p1DP == null) {
        this.p1DP = 0;
      }
    },
    // p2
    async getP2AL() {
      this.p2AL = await module.getReport(
        "api/p2/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      if (this.p2AL == null) {
        this.p2AL = 0;
      }
    },
    async getP2AP() {
      this.p2AP = await module.getReport(
        "api/p2/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p2AP == null) {
        this.p2AP = 0;
      }
    },
    async getP2BL() {
      this.p2BL = await module.getReport(
        "api/p2/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p2BL == null) {
        this.p2BL = 0;
      }
    },
    async getP2BP() {
      this.p2BP = await module.getReport(
        "api/p2/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p2BP == null) {
        this.p2BP = 0;
      }
    },
    async getP2CL() {
      this.p2CL = await module.getReport(
        "api/p2/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p2CL == null) {
        this.p2CL = 0;
      }
    },
    async getP2CP() {
      this.p2CP = await module.getReport(
        "api/p2/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p2CP == null) {
        this.p2CP = 0;
      }
    },
    async getP2DL() {
      this.p2DL = await module.getReport(
        "api/p2/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p2DL == null) {
        this.p2DL = 0;
      }
    },
    async getP2DP() {
      this.p2DP = await module.getReport(
        "api/p2/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p2DP == null) {
        this.p2DP = 0;
      }
    },
    // p3
    async getP3AL() {
      this.p3AL = await module.getReport(
        "api/p3/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      // console.log('p3 al', this.p3AL)
      if (this.p3AL == null) {
        this.p3AL = 0;
      }
    },
    async getP3AP() {
      this.p3AP = await module.getReport(
        "api/p3/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p3AP == null) {
        this.p3AP = 0;
      }
    },
    async getP3BL() {
      this.p3BL = await module.getReport(
        "api/p3/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p3BL == null) {
        this.p3BL = 0;
      }
    },
    async getP3BP() {
      this.p3BP = await module.getReport(
        "api/p3/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p3BP == null) {
        this.p3BP = 0;
      }
    },
    async getP3CL() {
      this.p3CL = await module.getReport(
        "api/p3/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p3CL == null) {
        this.p3CL = 0;
      }
    },
    async getP3CP() {
      this.p3CP = await module.getReport(
        "api/p3/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p3CP == null) {
        this.p3CP = 0;
      }
    },
    async getP3DL() {
      this.p3DL = await module.getReport(
        "api/p3/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p3DL == null) {
        this.p3DL = 0;
      }
    },
    async getP3DP() {
      this.p3DP = await module.getReport(
        "api/p3/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p3DP == null) {
        this.p3DP = 0;
      }
    },
    // p4
    async getP4AL() {
      this.p4AL = await module.getReport(
        "api/p4/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      if (this.p4AL == null) {
        this.p4AL = 0;
      }
    },
    async getP4AP() {
      this.p4AP = await module.getReport(
        "api/p4/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p4AP == null) {
        this.p4AP = 0;
      }
    },
    async getP4BL() {
      this.p4BL = await module.getReport(
        "api/p4/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p4BL == null) {
        this.p4BL = 0;
      }
    },
    async getP4BP() {
      this.p4BP = await module.getReport(
        "api/p4/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p4BP == null) {
        this.p4BP = 0;
      }
    },
    async getP4CL() {
      this.p4CL = await module.getReport(
        "api/p4/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p4CL == null) {
        this.p4CL = 0;
      }
    },
    async getP4CP() {
      this.p4CP = await module.getReport(
        "api/p4/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p4CP == null) {
        this.p4CP = 0;
      }
    },
    async getP4DL() {
      this.p4DL = await module.getReport(
        "api/p4/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p4DL == null) {
        this.p4DL = 0;
      }
    },
    async getP4DP() {
      this.p4DP = await module.getReport(
        "api/p4/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p4DP == null) {
        this.p4DP = 0;
      }
    },
    // p5
    async getP5AL() {
      this.p5AL = await module.getReport(
        "api/p5/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      if (this.p5AL == null) {
        this.p5AL = 0;
      }
    },
    async getP5AP() {
      this.p5AP = await module.getReport(
        "api/p5/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p5AP == null) {
        this.p5AP = 0;
      }
    },
    async getP5BL() {
      this.p5BL = await module.getReport(
        "api/p5/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p5BL == null) {
        this.p5BL = 0;
      }
    },
    async getP5BP() {
      this.p5BP = await module.getReport(
        "api/p5/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p5BP == null) {
        this.p5BP = 0;
      }
    },
    async getP5CL() {
      this.p5CL = await module.getReport(
        "api/p5/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p5CL == null) {
        this.p5CL = 0;
      }
    },
    async getP5CP() {
      this.p5CP = await module.getReport(
        "api/p5/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p5CP == null) {
        this.p5CP = 0;
      }
    },
    async getP5DL() {
      this.p5DL = await module.getReport(
        "api/p5/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p5DL == null) {
        this.p5DL = 0;
      }
    },
    async getP5DP() {
      this.p5DP = await module.getReport(
        "api/p5/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p5DP == null) {
        this.p5DP = 0;
      }
    },
    // p6
    async getP6AL() {
      this.p6AL = await module.getReport(
        "api/p6/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      if (this.p6AL == null) {
        this.p6AL = 0;
      }
    },
    async getP6AP() {
      this.p6AP = await module.getReport(
        "api/p6/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p6AP == null) {
        this.p6AP = 0;
      }
    },
    async getP6BL() {
      this.p6BL = await module.getReport(
        "api/p6/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p6BL == null) {
        this.p6BL = 0;
      }
    },
    async getP6BP() {
      this.p6BP = await module.getReport(
        "api/p6/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p6BP == null) {
        this.p6BP = 0;
      }
    },
    async getP6CL() {
      this.p6CL = await module.getReport(
        "api/p6/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p6CL == null) {
        this.p6CL = 0;
      }
    },
    async getP6CP() {
      this.p6CP = await module.getReport(
        "api/p6/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p6CP == null) {
        this.p6CP = 0;
      }
    },
    async getP6DL() {
      this.p6DL = await module.getReport(
        "api/p6/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p6DL == null) {
        this.p6DL = 0;
      }
    },
    async getP6DP() {
      this.p6DP = await module.getReport(
        "api/p6/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p6DP == null) {
        this.p6DP = 0;
      }
    },
    // p7
    async getP7AL() {
      this.p7AL = await module.getReport(
        "api/p7/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      if (this.p7AL == null) {
        this.p7AL = 0;
      }
    },
    async getP7AP() {
      this.p7AP = await module.getReport(
        "api/p7/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p7AP == null) {
        this.p7AP = 0;
      }
    },
    async getP7BL() {
      this.p7BL = await module.getReport(
        "api/p7/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p7BL == null) {
        this.p7BL = 0;
      }
    },
    async getP7BP() {
      this.p7BP = await module.getReport(
        "api/p7/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p7BP == null) {
        this.p7BP = 0;
      }
    },
    async getP7CL() {
      this.p7CL = await module.getReport(
        "api/p7/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p7CL == null) {
        this.p7CL = 0;
      }
    },
    async getP7CP() {
      this.p7CP = await module.getReport(
        "api/p7/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p7CP == null) {
        this.p7CP = 0;
      }
    },
    async getP7DL() {
      this.p7DL = await module.getReport(
        "api/p7/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p7DL == null) {
        this.p7DL = 0;
      }
    },
    async getP7DP() {
      this.p7DP = await module.getReport(
        "api/p7/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p7DP == null) {
        this.p7DP = 0;
      }
    },
    // p8
    async getP8AL() {
      this.p8AL = await module.getReport(
        "api/p8/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      if (this.p8AL == null) {
        this.p8AL = 0;
      }
    },
    async getP8AP() {
      this.p8AP = await module.getReport(
        "api/p8/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p8AP == null) {
        this.p8AP = 0;
      }
    },
    async getP8BL() {
      this.p8BL = await module.getReport(
        "api/p8/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p8BL == null) {
        this.p8BL = 0;
      }
    },
    async getP8BP() {
      this.p8BP = await module.getReport(
        "api/p8/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p8BP == null) {
        this.p8BP = 0;
      }
    },
    async getP8CL() {
      this.p8CL = await module.getReport(
        "api/p8/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p8CL == null) {
        this.p8CL = 0;
      }
    },
    async getP8CP() {
      this.p8CP = await module.getReport(
        "api/p8/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p8CP == null) {
        this.p8CP = 0;
      }
    },
    async getP8DL() {
      this.p8DL = await module.getReport(
        "api/p8/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p8DL == null) {
        this.p8DL = 0;
      }
    },
    async getP8DP() {
      this.p8DP = await module.getReport(
        "api/p8/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p8DP == null) {
        this.p8DP = 0;
      }
    },
    // p9
    async getP9AL() {
      this.p9AL = await module.getReport(
        "api/p9/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      if (this.p9AL == null) {
        this.p9AL = 0;
      }
    },
    async getP9AP() {
      this.p9AP = await module.getReport(
        "api/p9/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p9AP == null) {
        this.p9AP = 0;
      }
    },
    async getP9BL() {
      this.p9BL = await module.getReport(
        "api/p9/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p9BL == null) {
        this.p9BL = 0;
      }
    },
    async getP9BP() {
      this.p9BP = await module.getReport(
        "api/p9/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p9BP == null) {
        this.p9BP = 0;
      }
    },
    async getP9CL() {
      this.p9CL = await module.getReport(
        "api/p9/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p9CL == null) {
        this.p9CL = 0;
      }
    },
    async getP9CP() {
      this.p9CP = await module.getReport(
        "api/p9/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p9CP == null) {
        this.p9CP = 0;
      }
    },
    async getP9DL() {
      this.p9DL = await module.getReport(
        "api/p9/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p9DL == null) {
        this.p9DL = 0;
      }
    },
    async getP9DP() {
      this.p9DP = await module.getReport(
        "api/p9/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p9DP == null) {
        this.p9DP = 0;
      }
    },
    // p11
    async getP11AL() {
      this.p11AL = await module.getReport(
        "api/p11/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      if (this.p11AL == null) {
        this.p11AL = 0;
      }
    },
    async getP11AP() {
      this.p11AP = await module.getReport(
        "api/p11/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p11AP == null) {
        this.p11AP = 0;
      }
    },
    async getP11BL() {
      this.p11BL = await module.getReport(
        "api/p11/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p11BL == null) {
        this.p11BL = 0;
      }
    },
    async getP11BP() {
      this.p11BP = await module.getReport(
        "api/p11/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p11BP == null) {
        this.p11BP = 0;
      }
    },
    async getP11CL() {
      this.p11CL = await module.getReport(
        "api/p11/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p11CL == null) {
        this.p11CL = 0;
      }
    },
    async getP11CP() {
      this.p11CP = await module.getReport(
        "api/p11/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p11CP == null) {
        this.p11CP = 0;
      }
    },
    async getP11DL() {
      this.p11DL = await module.getReport(
        "api/p11/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p11DL == null) {
        this.p11DL = 0;
      }
    },
    async getP11DP() {
      this.p11DP = await module.getReport(
        "api/p11/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p11DP == null) {
        this.p11DP = 0;
      }
    },
    // p12
    async getP12AL() {
      this.p12AL = await module.getReport(
        "api/p12/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      if (this.p12AL == null) {
        this.p12AL = 0;
      }
    },
    async getP12AP() {
      this.p12AP = await module.getReport(
        "api/p12/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p12AP == null) {
        this.p12AP = 0;
      }
    },
    async getP12BL() {
      this.p12BL = await module.getReport(
        "api/p12/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p12BL == null) {
        this.p12BL = 0;
      }
    },
    async getP12BP() {
      this.p12BP = await module.getReport(
        "api/p12/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p12BP == null) {
        this.p12BP = 0;
      }
    },
    async getP12CL() {
      this.p12CL = await module.getReport(
        "api/p12/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p12CL == null) {
        this.p12CL = 0;
      }
    },
    async getP12CP() {
      this.p12CP = await module.getReport(
        "api/p12/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p12CP == null) {
        this.p12CP = 0;
      }
    },
    async getP12DL() {
      this.p12DL = await module.getReport(
        "api/p12/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p12DL == null) {
        this.p12DL = 0;
      }
    },
    async getP12DP() {
      this.p12DP = await module.getReport(
        "api/p12/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p12DP == null) {
        this.p12DP = 0;
      }
    },
    // p13
    async getP13() {
      this.p13 = await module.getReport(
        "api/p13/" + getUser().posyandu_id,
        `?month=${this.filter.month}`
      );
      if (this.p13 == null) {
        this.p13 = 0;
      }
    },
    // p14
    async getP14() {
      this.p14 = await module.getReport(
        "api/p14/" + getUser().posyandu_id,
        `?month=${this.filter.month}`
      );
      if (this.p14 == null) {
        this.p14 = 0;
      }
    },
    // p15
    async getP15AL() {
      this.p15AL = await module.getReport(
        "api/p15/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=male`
      );
      if (this.p15AL == null) {
        this.p15AL = 0;
      }
    },
    async getP15AP() {
      this.p15AP = await module.getReport(
        "api/p15/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=A&gender=female`
      );
      if (this.p15AP == null) {
        this.p15AP = 0;
      }
    },
    async getP15BL() {
      this.p15BL = await module.getReport(
        "api/p15/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=male`
      );
      if (this.p15BL == null) {
        this.p15BL = 0;
      }
    },
    async getP15BP() {
      this.p15BP = await module.getReport(
        "api/p15/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=B&gender=female`
      );
      if (this.p15BP == null) {
        this.p15BP = 0;
      }
    },
    async getP15CL() {
      this.p15CL = await module.getReport(
        "api/p15/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=male`
      );
      if (this.p15CL == null) {
        this.p15CL = 0;
      }
    },
    async getP15CP() {
      this.p15CP = await module.getReport(
        "api/p15/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=C&gender=female`
      );
      if (this.p15CP == null) {
        this.p15CP = 0;
      }
    },
    async getP15DL() {
      this.p15DL = await module.getReport(
        "api/p15/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.p15DL == null) {
        this.p15DL = 0;
      }
    },
    async getP15DP() {
      this.p15DP = await module.getReport(
        "api/p15/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.p15DP == null) {
        this.p15DP = 0;
      }
      this.showTable = true;
    },

    // LAPORAN ASI EKSLUSIF
    // exclusive1
    async getExclusive1AL() {
      this.exclusive1AL = await module.getReport(
        "api/exclusive/1a/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.exclusive1AL == null) {
        this.exclusive1AL = 0;
      }
    },
    async getExclusive1AP() {
      this.exclusive1AP = await module.getReport(
        "api/exclusive/1a/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.exclusive1AP == null) {
        this.exclusive1AP = 0;
      }
    },
    async getExclusive1BL() {
      this.exclusive1BL = await module.getReport(
        "api/exclusive/1b/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.exclusive1BL == null) {
        this.exclusive1BL = 0;
      }
    },
    async getExclusive1BP() {
      this.exclusive1BP = await module.getReport(
        "api/exclusive/1b/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.exclusive1BP == null) {
        this.exclusive1BP = 0;
      }
    },
    // exclusive2
    async getExclusive2AL() {
      this.exclusive2AL = await module.getReport(
        "api/exclusive/2a/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.exclusive2AL == null) {
        this.exclusive2AL = 0;
      }
    },
    async getExclusive2AP() {
      this.exclusive2AP = await module.getReport(
        "api/exclusive/2a/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.exclusive2AP == null) {
        this.exclusive2AP = 0;
      }
    },
    async getExclusive2BL() {
      this.exclusive2BL = await module.getReport(
        "api/exclusive/2b/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=male`
      );
      if (this.exclusive2BL == null) {
        this.exclusive2BL = 0;
      }
    },
    async getExclusive2BP() {
      this.exclusive2BP = await module.getReport(
        "api/exclusive/2b/" + getUser().posyandu_id,
        `?month=${this.filter.month}&age_category=D&gender=female`
      );
      if (this.exclusive2BP == null) {
        this.exclusive2BP = 0;
      }
    },

    async getMaterialStock() {
      let response = await module.paginate(
        `api/material-stock/report/${getUser().posyandu_id}`
      );

      this.materialStocks = response.data;
    },

    btnPrintOnClick() {
      const localOptions = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: [
          "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
          "https://unpkg.com/kidlat-css/css/kidlat.css",
          "@/view/pages/weighing-notes/report.css"
        ],
      };
      this.$htmlToPaper("printMe", localOptions);
    },
  },
  mounted() {
    if (
      getUser().role_key_name != "super_admin" &&
      getUser().user_type_id != 3
    ) {
      this.getPosyandu(getUser().posyandu_id);
    } else {
      this.getPosyanduOption();
    }
  },
};
</script>

<style>
.height-tree-line {
  height: 36px;
}
.height-two-line {
  height: 31px;
}
</style>